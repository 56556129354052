<template>
  <ul>
    <li>
      <router-link to="/">News</router-link>
    </li>
    <li v-if="isLoggedIn">
      <router-link to="/members">Mitglieder</router-link>
    </li>
    <li>
      <router-link to="/chor">Über uns</router-link>
    </li>
    <li>
      <router-link to="/medien">Medien</router-link>
    </li>
    <li class="separate" v-if="isLoggedIn">
      <router-link to="/noten">Noten</router-link>
    </li>
    <li v-if="isLoggedIn">
      <router-link to="/calendar">Kalender</router-link>
    </li>
    <li v-if="isActive">
      <router-link to="/mails">Mails</router-link>
    </li>
    <li v-if="!isLoggedIn">
      <router-link to="/login">Login</router-link>
    </li>
    <li v-if="isLoggedIn">
      <router-link to="/verein">Vereinsinfos</router-link>
    </li>
    <li v-if="isLoggedIn">
      <router-link to="/settings">Einstellungen</router-link>
    </li>
    <li v-if="isLoggedIn && showKlaus">
      <a v-on:click="klaus()">Klaus</a>
    </li>
    <li class="separate" v-if="isLoggedIn">
      <router-link to="/logout">Logout</router-link>
    </li>
  </ul>
  <!-- https://www.facebook.com/Chantier-Vocal-399292550087788/ -->
</template>

<script>
import { mapState } from 'pinia'
import { useUserStore } from '@/stores'

export default {
  name: 'MainMenu',
  props: ['showKlaus'],
  data () {
    return {}
  },
  methods: {
    klaus: function (el) {
      if (typeof el === 'undefined') {
        el = document.body
      }
      for (let e of el.childNodes) {
        if (e.nodeType === 1) {
          this.klaus(e)
        } else if (e.nodeType === 3) {
          const n = e.nodeValue.split(' ').filter(str => str.trim()).length
          if (n) {
            e.nodeValue = 'Klaus '.repeat(n)
          }
        }
      }
    }
  },
  computed: {
    ...mapState(useUserStore, [
      'isLoggedIn',
      'isActive'
    ])
  }
}
</script>

<style scoped>
ul {
  list-style: none;
  margin: 0;
  padding: 1.5em;
}

li {
  width: 7em;
  border-bottom: solid 1px #c80700;
}

a {
  color: black;
  text-decoration: none;
  padding: 0.5em;
  display: block;
  white-space: nowrap;
}

.router-link-exact-active {
  background-color: #c80700;
  color: white;
}

.separate {
  margin-top: 2em;
}

@media only screen and (max-width: 800px) {
  ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0.3em 0;
  }

  li {
    width: 6.5em;
  }

  a {
    padding: 0.5em 0.2em;
  }

  .separate {
    margin-top: 0;
  }
}
</style>
