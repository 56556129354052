import { defineStore } from 'pinia'
import api from '@/util/api'
import { useStore as useUserStore } from './user'

export const useStore = defineStore('members', {
  state: () => ({ members: [] }),
  getters: {
    director: (state) => { return state.members.find(usr => usr.section === 'Chorleitung') },
    activeMembersBySections: (state) => {
      return state.members.reduce(function (acc, member) {
        if (member.section === 'Chorleitung' || member.role === 'inactive') {
          return acc
        }
        let ix = acc.findIndex(tup => tup.section === member.section)
        if (ix === -1) {
          acc.push({
            section: member.section,
            members: []
          })
          ix = acc.length - 1
        }
        acc[ix].members.push(member)
        return acc
      }, [])
    },
    inactiveMembers: (state) => {
      return state.members.reduce(function (acc, member) {
        if (member.role !== 'inactive') {
          return acc
        }
        acc.push(member)
        return acc
      }, [])
    }
  },
  actions: {
    setMembers (members) {
      this.members = members
    },
    syncMembers () {
      const userStore = useUserStore()
      if (userStore.isLoggedIn) {
        api.get('/users/private')
          .then(response => {
            this.setMembers(response.data)
          }).catch(error => {
            alert(error)
          })
      } else {
        this.setMembers([])
      }
    },
  }
})