import { createApp } from 'vue'
import { createPinia } from 'pinia'
import { useUserStore, useMembersStore } from '@/stores'
import router from './router'
import MainLayout from './components/MainLayout.vue'

const app = createApp(MainLayout)
const pinia = createPinia()
app.use(pinia)
app.use(router)
app.mount('#app')

const userStore = useUserStore()
const membersStore = useMembersStore()
userStore.syncAuthentication()
membersStore.syncMembers()
