<template>
  <div></div>
</template>

<script>
import { mapActions } from 'pinia'
import { useUserStore , useMembersStore } from '@/stores'

export default {
  name: 'LogoutPage',
  data () {
    return {
    }
  },
  created: function () {
    localStorage.removeItem('id_token')
    this.syncAuthentication()
    this.syncMembers()
    this.$router.push('/')
  },
  methods: {
    ...mapActions(useUserStore, ['syncAuthentication']),
    ...mapActions(useMembersStore, ['syncMembers'])
  }
}
</script>

<!-- Add 'scoped' attribute to limit CSS to this component only -->
<style>
</style>
