<template>
  <form v-on:submit.prevent="send">
    <fieldset :disabled="submitting">
      <slot></slot>
      <div class="submit">
        <input type="submit" :value="submitText">
        <div v-if="msg" v-bind:style="{ color: ok ? 'green' : 'red' }">
          <span v-if="ok">:) </span>
          <span v-else>:( </span>
          <span>{{msg}}</span>
        </div>
      </div>
    </fieldset>
  </form>
</template>

<script>
import api from '@/util/api.js'

export default {
  name: 'SynchronousForm',
  props: {
    endpoint: {
      type: String,
      required: true
    },
    submitText: {
      type: String,
      required: true
    },
    payload: {
      type: [Object, String, Number, Array, FormData],
      required: true
    },
    validationErr: {
      type: String,
      default: ''
    },
    successMsg: {
      type: String,
      default: 'Erfolgreich'
    },
    errMsg: {
      type: String,
      default: 'Fehler'
    }
  },
  data () {
    return {
      submitting: false,
      ok: true,
      msg: ''
    }
  },
  methods: {
    send: function () {
      if (this.validationErr) {
        this.msg = this.validationErr
        this.ok = false
        return
      } else {
        this.msg = ''
        this.ok = true
      }

      this.submitting = true
      api.post(this.endpoint, this.payload
      ).then(response => {
        this.ok = true
        this.$emit('ok', response.data)
        this.msg = this.successMsg
      }).catch(error => {
        console.log("error", error);
        this.ok = false
        this.$emit('err', error.data)
        if (error.response && error.response.status === 409) {
          this.msg = 'Datenbankfehler – doppeltes Einfügen?'
        } else {
          this.msg = this.errMsg
        }
      }).finally(() => {
        this.submitting = false
      })
    }
  }
}
</script>

<style scoped>
form {
  margin-bottom: 1em;
}

form input:not([type=submit]):not([type=checkbox]), form textarea, form select {
  display: block;
  width: 100%;
}

.submit {
  margin-top: 1em;
  display: flex;
  align-items: center;
}

.submit div {
  font-size: small;
  margin-left: 1em;
}

input[type=submit] {
  background-color: #c90900;
  padding: 0.6em 0.8em;
  font-weight: 700;
  font-size: small;
  border: 2px solid #c90900;
  background-color: white;
  color: #c90900;
}

input[type=submit]:hover {
  color: white;
  background-color: #c90900;
}

input[type=submit]:disabled,
input[type=submit]:disabled:hover {
  border: 2px solid gray;
  color: gray;
  background-color: white
}

.required {
  position: relative;
}

.required:before {
  content:"*";
  display: block;
  font-size: large;
  position: absolute;
  font-weight: bold;
  color: #c90900;
  left: -0.5em;
}
</style>