<template>
  <div>
    <div class="radio">
      <input type="radio" id="agenda" value="AGENDA" v-model="mode">
      <label for="agenda">Agenda</label>
      <input type="radio" id="month" value="MONTH" v-model="mode">
      <label for="month">Monat</label>
      <input type="radio" id="week" value="WEEK" v-model="mode">
      <label for="week">Woche</label>
    </div>
    <iframe :src="src" style="border-width:0" width="100%" height="600" frameborder="0" scrolling="no"></iframe>
  </div>
</template>

<script>
export default {
  data () {
    return {
      mode: "AGENDA",
    }
  },
  computed: {
    src: function() {
      console.log(this.mode)
      return "https://calendar.google.com/calendar/embed?height=600&wkst=2&ctz=Europe%2FBerlin&showTabs=0&showPrint=0&mode=" + this.mode + "&showTitle=0&showTz=0&showCalendars=0&src=ZTZlZWQ4NWExM2YyYjk3Y2M1Zjc3ZDU1YzRkZGYwMGVkMWYwY2Y5MzY1YjEwMDYxNDQ4ZTQyOTQ3ZTcyMjJhNEBncm91cC5jYWxlbmRhci5nb29nbGUuY29t&src=aHQzamxmYWFjNWxmZDYyNjN1bGZoNHRxbDhAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&color=%23ff0000"
    }
  },
  methods: {
    toggle: function() {
      if (this.mode === "AGENDA") {
        this.mode = "MONTH";
      } else {
        this.mode = "AGENDA";
      }
    }
  }
}
</script>


<style scoped>
iframe {
  margin-top: 1em;
  filter: saturate(0.5) hue-rotate(145deg);
}
</style>