<template>
  <div class="hello">
    <h1>Konzerte: Best of Pop(pe) &amp; Beethoven 9</h1>
    <p>Herzliche Einladung zu unserem nächsten Konzert!</p>
    <img class="fullwidth" src="https://chantiervocal-public.s3.amazonaws.com/Flyer Best of Pop(pe).jpeg" alt="Plakat Best of Pop(pe)" />
    <p>Außerdem führen wir am 30.11., gemeinsam mit dem Maria-Ward-Chor und dem Symphonieorchester Wilde Gungl, Beethovens <em>Ode an die Freude</em> auf:</p>
    <img class="fullwidth" src="https://chantiervocal-public.s3.amazonaws.com/Flyer Beethoven 2024-11-30.png" alt="Plakat Beethoven" />
    <img class="fullwidth" src="https://chantiervocal-public.s3.amazonaws.com/Infos Beethoven 2024-11-30.jpeg" alt="Plakat Beethoven" />
  </div>

</template>

<script>
export default {
  name: 'FrontPage',
  data () {
    return {}
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
