<template>
  <div id="app" class="container">
    <header>
      <div id="triangle" @click="showKlaus=true"></div>
      <div>
        <h1>CHANTIER VOCAL</h1>
        <h2>Chor in München</h2>
      </div>
    </header>
    <div class="content-overlay"></div>

    <main>
      <div id="nav">
        <main-menu :showKlaus="showKlaus"></main-menu>
      </div>
      <div id="content">
        <router-view></router-view>
      </div>
    </main>

    <footer>
      Impressum
      <div>
        <div class="hidden" data-text="niroM ialokiN"></div>
        <div class="hidden" data-text="63 eßartsdargleB"></div>
        <div class="hidden" data-text="(reih thcin dnis neborP eid ,nien)"></div>
        <div class="hidden" data-text="nehcnüM 69708"></div>
      </div>
      <div>
        Kontakt:
        <div class="e-mail" data-user="nimda :liamE" data-website="ed.lacovreitnahc"></div>
        <div class="e-mail" data-user="gnutielrohc :liamE" data-website="ed.lacovreitnahc"></div>
      </div>
      <div>
        <div class="hidden" data-text="311566437510 :nofeleT"></div>
      </div>
    </footer>
  </div>
</template>

<script>
import MainMenu from './MainMenu'
import { mapState } from 'pinia'
import { useUserStore } from '@/stores'

export default {
  name: 'MainLayout',
  components: { 'main-menu': MainMenu },
  data () {
    return {
      showKlaus: false
    }
  },
  methods: {
  },
  computed: mapState(useUserStore, [
     'isLoggedIn'
  ])
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
@import '../assets/normalize.css';
@import url('https://fonts.googleapis.com/css?family=Lato:400,700,700i,900&subset=latin-ext');

body {
  background-color: #edecea;
  overflow: scroll;
  font-family: 'Lato', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

header {
  background-color: #c80700;
  color: white;
  display: flex;
  align-items: center;
  font-family: 'Lato Light', Helvetica, Arial, sans-serif;
}

#triangle {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 50px 86.6px 50px;
  border-color: transparent transparent white transparent;
  margin: 1.5em;
}

#app {
  max-width: 1024px;
  margin: 60px auto;
}

main {
  display: flex;
}

/* This is so there's no jump when the headline breaks*/
h1, h2 {
  margin: 0.4em 0;
  font-weight: normal;
}

a {
  cursor: pointer;
  user-select: none;
}

input:not([type]), input[type=text], input[type=email], input[type=tel], input[type=submit], input[type=password], select, textarea, .button {
  font-family: 'Lato', Helvetica, Arial, sans-serif;
  box-sizing: border-box;
  border: 1px solid #9a9ca1;
  height: 40px;
  padding: 0 15px;
  width: 100%;
}

textarea {
  resize: vertical;
  height: 10em;
  padding-top: 10px;
}

label {
  font-size: small;
  display: block;
  color: gray;
}

select {
  background-color: white;
}

fieldset {
  border: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
}

fieldset > div {
  margin: 0.5em 0;
}

#content {
  padding: 1em;
  width: 100%;
  box-sizing: border-box;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}

footer {
  text-align: center;
  color: gray;
  margin-top: 100px;
  user-select: none;
  font-size: small;
}

.e-mail:before {
  content: attr(data-website) "\0040" attr(data-user);
  unicode-bidi: bidi-override;
  direction: rtl;
}

.hidden:before {
  content: attr(data-text);
  unicode-bidi: bidi-override;
  direction: rtl;
}


.fadein-enter-active {
  transition: all 1s;
}

.fadein-leave-active {
  transition: none;
}

.fadein-enter, .fadein-leave-to {
  opacity: 0;
}

/* === responsive === */

@media only screen and (max-width: 800px) {
  #app {
    margin-top: 0;
  }

  main {
    flex-direction: column;
  }
}
</style>
