import { defineStore } from 'pinia'
import api from '@/util/api'

export const useStore = defineStore('user', {
  state: () => ({
    userId: null,
    role: null
  }),
  getters: {
    isLoggedIn: (state) => { return Boolean(state.role) },
    isActive: (state) => { return state.role === 'normal' || state.role === 'attndtaker' || state.role === 'director' },
    isAttndTaker: (state) => { return state.role === 'attndtaker' || state.role === 'director' },
    isDirector: (state) => { return state.role === 'director' },
    isEditor: (state) => { return state.role === 'editor' || state.role === 'director' },
  },
  actions: {
    syncAuthentication () {
      const token = localStorage.getItem('id_token')
      if (token) {
        api.defaults.headers.common['Authorization'] = 'Bearer ' + token
        const ident = JSON.parse(atob(token.split('.')[1]))
        this.userId = parseInt(ident.sub, 10)
        this.role = ident.role
      } else {
        delete api.defaults.headers.common['Authorization']
        this.userId = null
        this.role = null
      }
    },
  }
})
