<template>
  <sync-form v-bind="formdata" v-on:ok="ok">
    <div>
      <label>Wen?</label>
      <select v-model="selected">
        <option v-for="member in members" :value="member.userId" v-bind:key="member.userId">{{member.name}}</option>
      </select>
    </div>
  </sync-form>
</template>

<script>
import SynchronousForm from '@/components/Generic/SynchronousForm'

export default {
  name: 'ChoirMemberDelete',
  components: {
    'sync-form': SynchronousForm
  },
  props: ['members'],
  data () {
    return {
      selected: -1
    }
  },
  methods: {
    ok: function () {
      this.$emit('ok')
    }
  },
  computed: {
    formdata: function () {
      return {
        endpoint: '/users/delete',
        payload: this.selected,
        submitText: 'Sänger löschen',
        validationErr: this.selected < 0 ? 'Kein Sänger ausgewählt' : ''
      }
    }
  }
}
</script>

<style scoped>
form {
  max-width: 20em;
}
</style>
