<template>
  <div>
    <h1>Über uns</h1>
    <img class="fullwidth" src="../assets/Chor2023.jpg" alt="Besetzung 2019" />
    <p>Herzlich willkommen bei Chantier Vocal e. V.!</p>
    
    <p>Wir haben uns „Stimmbaustelle“ genannt (frz. „chantier“ = „Baustelle“), weil Chor manchmal ein bisschen so wie eine Baustelle ist. Nämlich ein Entstehungsprozess, viel Arbeit — nicht zuletzt für unsere Chorleiterin Amina Flizsar — aber auch ein Ergebnis, das sich lohnt. Und auch laut? Aber hallo! Wie's sich für einen Chor eben gehört.</p>

    <h2>Musik</h2>

    <p>Wir lieben anspruchsvolle* und vielseitige Chormusik. Wir wagen uns gerne an fremde Sprachen (wie Finnisch, Polnisch oder Lettisch) und legen uns auf keine Genres oder Epochen fest. Von Gregorianik bis Filmmusik ist alles dabei. Unsere Stückauswahl umfasst bekannte Namen wie Gjeilo, Brahms, Mäntyjärvi und Monteverdi sowie eigene Arrangements und weniger bekannte internationale Komponist:innen.</p>
    <p>Einige unserer Lieblingsstücke sind:
      <ul>
        <li>Abendlied (Rheinberger)</li>
        <li>Abschied vom Walde (Mendelssohn)</li>
        <li>Cantique de Jean Racine (Fauré)</li>
        <li>Cloudburst (Whitacre)</li>
        <li>Daemon Irrepit Callidus (Orbán)</li>
        <li>Ecco Mormorar l&#39;Onde (Monteverdi)</li>
        <li>Hymne à la Nuit (Rameau)</li>
        <li>If Ye Love Me (Tallis)</li>
        <li>Nearer my God to Thee</li>
        <li>Sügismaastikud (Tormis)</li>
        <li>The Girl that I Love (Crossland)</li>
        <li>Stars (Ešenvalds)</li>
        <li>Viva la Vida (Coldplay)</li>
      </ul>
    </p>
    <p>
    Ein- bis zweimal jährlich geben wir Konzerte. Zudem kooperieren wir gelegentlich mit anderen Chören und tragen etwa dreimal im Jahr zum Gottesdienst in der Passionskirche bei; das ist unsere Gegenleistung für die Nutzung des Gemeindesaals.</p>
  
    <h2>Proben &amp; Mitgliedschaft</h2>

    <p>Melde dich gerne bei uns, wenn du Interesse hast (und Tenor oder Bass singst – leider können wir aktuell nur diese Stimmen aufnehmen). Wir proben immer sonntags von 18:30 Uhr bis 21:00 Uhr im Gemeindesaal der Passionskirche (Tölzer Straße 17, U3 Obersendling). Es gibt immer eine Kuchenpause!</p>
    <p>Der monatliche Mitgliedsbeitrag beträgt im Moment 16 € und unterstützt die Finanzierung unserer wundervollen Chorleiterin, die Mitgliedschaft im Dachverband „Bayerischer Sängerbund“ und leistet meist auch einen Zuschuss zu unseren Chorfahrten.</p>
    <p>* Unter „anspruchsvoll“ verstehen wir, dass grundlegendes musikalisches Wissen vorhanden sein sollte. Du musst nicht perfekt vom Blatt singen können, aber es ist hilfreich, wenn du bereits Chorerfahrung hast oder ein Instrument spielst.</p>
     
    <h2>Chorfahrten</h2>
  
    <p>Jährlich machen wir ein Probenwochenende im Sommer oder Herbst sowie eine fünftägige Chorfahrt im Januar zur Fraueninsel. Unsere Chorfahrten sind jedes Jahr ein besonderes Highlight, bei dem wir uns intensiv auf unsere Konzerte vorbereiten und bei Spiel, Speis und Gesang als Gruppe zusammenwachsen.</p>
  
    <h2>Chorgemeinschaft</h2>
    
    <p>Die Freude am Singen vereint uns und schafft ein familiäres Miteinander. Wir sind eine Gemeinschaft, die sich auch außerhalb der Probe bei Freizeitaktivitäten zusammenfindet. Wir lieben Wandern, Radfahren, Spieleabende, Kleidertauschpartys, Pflanzenableger, Basteln, Tanzen, Klettern, politisches Engagement, Singen am Lagerfeuer, Schafe streicheln, Klaus, Wortwitze, Vollmund, Moon Cups und unser Chormaskottchen, die Notenfresserin Narma.</p>
    <p> Bei uns entstehen nicht nur harmonische Töne, sondern auch echte Freundschaften. Neue Gesichter finden bei uns schnell Anschluss, denn wir sind einfach richtig nett, sympathisch und bescheiden.</p>
    <p>Wir teilen Werte wie Nachhaltigkeit, Gleichberechtigung und Weltoffenheit.</p>

    <h2>Chorgeschichte</h2>
    <p>Chantier Vocal wurde 2006 von Abélia Nordmann im Rahmen ihrer Facharbeit gegründet. Anschließend leitete ein Dreiergespann bestehend aus Nora Lenzen, Sebastian Hess und Julian Mohr unseren Chor, bis Julian Mohr bis 2017 die alleinige Leitung übernahm. Bis Februar 2019 leitete Lorenz Höß unseren Chor und initiierte auch die CD-Aufnahme unseres Konzerts „Beschwörung“. Danach hatte Katharina Poppe die musikalische Leitung inne, bis Amina Flizsar 2023 als Chorleiterin übernahm.</p>
  
    <h2>Kontakt</h2>
    <p>Für alle Anliegen und Fragen stehen wir gerne zur Verfügung. Melde dich gerne unter <span class="e-mail" data-user="gnutielrohc" data-website="ed.lacovreitnahc"></span> – wir freuen uns von dir zu hören!</p>
  </div>
</template>

<script>
</script>
