<template>
  <div>
    <div class="radio">
      <input type="radio" id="archive" value="archive" v-model="action">
      <label for="archive">Archiv</label>
      <input type="radio" id="send" value="send" v-model="action">
      <label for="send">Mail senden</label>
    </div>
    <mail-send v-if="action == 'send'"></mail-send>
    <mail-archive v-if="action == 'archive'"></mail-archive>
  </div>
</template>

<script>
import MailArchive from './Mails/Archive'
import MailSend from './Mails/Send'
import { mapState } from 'pinia'
import { useUserStore } from '@/stores'

export default {
  name: 'MailsPage',
  components: {
    'mail-send': MailSend,
    'mail-archive': MailArchive
  },
  data: function () {
    return {
      action: 'archive'
    }
  },
  computed: {
    ...mapState(useUserStore, [
      'users',
      'isDirector'
    ])
  }
}
</script>

<style>
.radio input[type="radio"] {
    display: none;
    z-index: 100;
    color: red;
}

.radio {
  display: flex;
}

.radio label {
  color: inherit;
  cursor: pointer;
  font-size: inherit;
  padding-right: 1em;
}

input[type="radio"]:checked + label {
  border-color: #c80700;
  color: #c80700;
}

</style>