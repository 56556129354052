import {createRouter, createWebHistory} from 'vue-router'
import AboutUsPage from '@/components/AboutUsPage'
import CalendarPage from '@/components/CalendarPage'
import ClubInternalInfoPage from '@/components/ClubInternalInfoPage'
import FrontPage from '@/components/FrontPage'
import LoginPage from '@/components/LoginPage'
import LoginTokenPage from '@/components/LoginTokenPage'
import LogoutPage from '@/components/LogoutPage'
import MediaPage from '@/components/MediaPage'
import MailsPage from '@/components/MailsPage'
import ChoirMembersPage from '@/components/ChoirMembersPage'
import SettingsPage from '@/components/SettingsPage'
import ScoresPage from '@/components/ScoresPage'

import { useUserStore } from '@/stores/index'

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      name: 'FrontPage',
      component: FrontPage
    },
    {
      path: '/chor',
      name: 'AboutUsPage',
      component: AboutUsPage
    },
    {
      path: '/calendar',
      name: 'CalendarPage',
      component: CalendarPage
    },
    {
      path: '/noten',
      name: 'ScoresPage',
      component: ScoresPage,
      meta: { auth: 'normal' }
    },
    {
      path: '/medien',
      name: 'MediaPage',
      component: MediaPage
    },
    {
      path: '/mails',
      name: 'MailsPage',
      component: MailsPage,
      meta: { auth: 'normal' }
    },
    {
      path: '/settings',
      name: 'SettingsPage',
      component: SettingsPage,
      meta: { auth: 'normal' }
    },
    {
      path: '/verein',
      name: 'ClubInternalInfoPage',
      component: ClubInternalInfoPage,
      meta: { auth: 'normal' }
    },
    {
      path: '/login',
      name: 'LoginPage',
      component: LoginPage
    },
    {
      path: '/login_token/:token',
      name: 'LoginTokenPage',
      component: LoginTokenPage
    },
    {
      path: '/logout',
      name: 'LogoutPage',
      component: LogoutPage
    },
    {
      path: '/members',
      name: 'ChoirMembersPage',
      component: ChoirMembersPage,
      meta: { auth: 'normal' }
    }
  ]
})

router.beforeEach((to, from, next) => {
  const userStore = useUserStore()
  const authRequired = to.matched.some((route) => route.meta.auth)
  // document.title = to.meta.title
  if (authRequired && !userStore.isLoggedIn) {
    next('/login')
  } else {
    next()
  }
})

export default router
