<template>
  <div>
    <h1>Archiv</h1>
    <div v-for="mail in mails" :key="mail.mailId">
      <hr>
      <label>{{mail.dateText}}</label>
      <h2>{{mail.subject}}</h2>
      <p class="pre">{{mail.body}}</p>
    </div>
  </div>
</template>

<script>
import { mapState } from 'pinia'
import { useUserStore } from '@/stores'
import api from '@/util/api'

export default {
  name: 'MailArchive',
  data () {
    return {
      mails: []
    }
  },
  methods: {
  },
  computed: {
    ...mapState(useUserStore, [
      'users',
      'isDirector'
    ])
  },
  created: function () {
    let that = this
    const dtf = new Intl.DateTimeFormat('de-DE', {
      month: 'long',
      day: 'numeric',
      weekday: 'long'
    })
    api.get('/mail/archives')
      .then(response => {
        that.mails = response.data.map(m => {
          m.date = new Date(m.date)
          m.dateText = dtf.format(m.date)
          return m
        }).sort((m1, m2) => m2.date - m1.date)
      }).catch(alert)
  }
}
</script>

<style>
.pre {
  white-space: pre-wrap;
}
</style>