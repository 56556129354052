<template>
  <div v-if="isLoggedIn">
    <toggle>
      <template v-slot:title>{{member.name}}</template>
      <edit-member v-if="isDirector"
                 :member="member"
                 inputType="update"
                 v-on:refresh="refresh"></edit-member>
      <edit-member v-else-if="isSelf"
                 :member="member"
                 inputType="updateSelf"
                 v-on:refresh="refresh"></edit-member>
      <show-member v-else :member="member"></show-member>
    </toggle>
  </div>
  <div v-else>
    {{member.name}}
  </div>
</template>

<script>
import ChoirMemberForm from './Form'
import ChoirMemberDisplay from './Display'
import FoldingToggleComponent from '@/components/Generic/FoldingToggleComponent'
import { mapState } from 'pinia'
import { useUserStore } from '@/stores'

export default {
  name: 'ChoirMemberComponent',
  props: ['member'],
  components: {
    'edit-member': ChoirMemberForm,
    'show-member': ChoirMemberDisplay,
    'toggle': FoldingToggleComponent
  },
  data () {
    return {
    }
  },
  methods: {
    refresh: function () {
      this.$emit('refresh')
    }
  },
  computed: {
    isSelf: function() {
      return this.userId === this.member.userId
    },
    ...mapState(useUserStore, [
      'isLoggedIn', 'userId', 'isDirector'
    ])
  }
}
</script>

<style>
</style>
