<template>
  <div>
    <h1>Passwort ändern</h1>
    <sync-form id="settings" v-bind="formdata" v-on:ok="ok">
      <div>
        <label for="newPassword">Neues Passwort</label>
        <input type="password" id="newPassword" v-model="newPassword">
      </div>
    </sync-form>

    <h1>Feedback zur Chorwebseite geben</h1>
    Schreibt mir ...
    <ul>
      <li>Ideen für Funktionen, die noch nicht oben stehen</li>
      <li>wenn ihr <a href="#" v-on:click="errorExample">so einen Dialog</a> seht, denn das sollte nicht passieren</li>
      <li>wenn ihr unhilfreiche Fehlermeldungen bekommt</li>
      <li>wenn ihr Sicherheitslücken entdeckt (dass ihr Mails verschicken könnt, ist allerdings Absicht)</li>
      <li>was ihr an der Webseite anders designen würdet, und wie</li>
    </ul>
    <sync-form v-bind="formdataFeedback" v-on:ok="feedbackSentSuccessfully">
      <div>
        <label>Feedback</label>
        <textarea v-model="feedbackText"></textarea>
      </div>
    </sync-form>
  </div>
</template>

<script>
import hash from '@/util/hash.js'
import SynchronousForm from '@/components/Generic/SynchronousForm'

export default {
  name: 'SettingsPage',
  components: {
    'sync-form': SynchronousForm
  },
  data () {
    return {
      newPassword: '',
      feedbackText: ''
    }
  },
  methods: {
    ok: function () {
      this.newPassword = ''
    },
    feedbackSentSuccessfully: function () {
      this.feedbackText = ''
    },
    errorExample: function () {
      console.log('??')
      alert('Beispiel')
    }
  },
  computed: {
    formdata: function () {
      const newPassword = hash('bass4lyfe', this.newPassword)

      return {
        endpoint: '/users/changePassword',
        payload: {
          newPassword: newPassword
        },
        submitText: 'Passwort ändern',
        validationErr: this.newPassword ? '' : 'Bitte Passwort eingeben',
        errMsg: 'Falsches Passwort',
        successMsg: 'Passwort geändert'
      }
    },
    payload: function () {
      let recipients = {
        tag: 'Users',
        contents: [1]
      }
      let formData = new FormData()
      formData.append('recipients', JSON.stringify(recipients))
      formData.append('subject', 'Feedback Chorwebseite')
      formData.append('body', this.feedbackText)
      return formData
    },
    formdataFeedback: function () {
      return {
        endpoint: '/mail/sendNormal',
        submitText: 'Senden',
        payload: this.payload,
        successMsg: 'Gesendet'
      }
    }
  }
}
</script>

<style>
#settings {
  max-width: 20em;
}
</style>