<template>
  <div class="container">
    <sync-form v-bind="formdataLoginWithPassword" v-on:ok="ok" v-on:err="err">      
      <p>Passwort vergessen? Melde dich unten mit Login-Link an, und setze dann im internen Bereich ein neues Passwort.</p>
      <label for="user">Email</label>
      <input type="email" v-model="email">
      <label for="password">Passwort</label>
      <input type="password" v-model="password">
    </sync-form>
    <sync-form v-bind="formdataSendLoginLink">
      <p>Hier kannst du dich ohne Passwort anmelden – du bekommst stattdessen einen Login-Link zugeschickt.</p>
      <label for="user">Email</label>
      <input type="email" v-model="email_loginlink">        
    </sync-form>
  </div>
</template>

<script>
import { mapActions, mapState } from 'pinia'
import { useUserStore, useMembersStore } from '@/stores'
import SynchronousForm from '@/components/Generic/SynchronousForm'
import hash from '../util/hash'

export default {
  name: 'LoginPage',
  components: {
    'sync-form': SynchronousForm
  },
  data: function () {
    return {
      email_loginlink: '',
      email: '',
      password: '',
    }
  },
  methods: {
    ok: function (data) {
      localStorage.setItem('id_token', data)
      if (this.forgot) {
        this.forgot = false
      } else {
        this.syncAuthentication()
        this.syncMembers()
        this.$router.push('/noten')
      }
    },
    err: function () {
      this.password = ''
    },
    ...mapActions(useUserStore, ['syncAuthentication']),
    ...mapActions(useMembersStore, ['syncMembers'])
  },
  computed: {
    payloadSendLoginLink: function () {
      return '"' + this.email_loginlink.trim().toLowerCase() + '"'
    },
    payloadLoginWithPassword: function () {
      return {
        email: this.email.trim().toLowerCase(),
        password: hash('bass4lyfe', this.password)
      }
    },
    formdataSendLoginLink: function () {
      return {
        endpoint: '/sendLoginLink',
        submitText: 'Anmeldung ohne Passwort',
        payload: this.payloadSendLoginLink,
        successMsg: 'Alles klar! Du hast eine Email bekommen.',
        errMsg: "Anmeldung nicht erfolgreich"
      }
    },
    formdataLoginWithPassword: function () {
      return {
        endpoint: '/loginWithPassword',
        submitText: 'Anmeldung mit Passwort',
        payload: this.payloadLoginWithPassword,
        errMsg: "Anmeldung nicht erfolgreich"
        /* No successMsg, since the user is immediately redirected on success anyway */
      }
    },
    ...mapState(useUserStore, [
      'userId'
    ])
  }
}
</script>

<style scoped>

form {
  border-radius: 0.6em;
}

.container > form {
  min-width: 15em;
  max-width: 30em;
  background-color: rgba(150, 0, 100, 0.1);
  padding: 1em;
  margin-bottom: 1em;
}
</style>
