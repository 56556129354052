<template>
  <div class="checkbox" :class='{ active: isChecked }' @click="toggle">
    <span :class="{ checked: isChecked }">{{ isChecked ? '✔' : '' }}</span>
    <label>{{label}}</label>
  </div>
</template>

<script>
export default {
  name: 'CheckboxComponent',
  props: {
    modelValue: {
      type: [Boolean, Array],
      required: true
    },
    label: {
      type: String,
      required: true
    },
    arrValue: {
      required: false
    }
  },
  data: function () {
    return {
    }
  },
  methods: {
    toggle: function () {
      let newValue
      if (this.isBool) {
        newValue = !this.modelValue
      } else {
        const ix = this.modelValue.indexOf(this.arrValue)
        if (ix > -1) {
          newValue = this.modelValue.filter(v => v !== this.arrValue)
        } else {
          newValue = this.modelValue.concat(this.arrValue)
        }
      }
      this.$emit('update:modelValue', newValue)
    }
  },
  computed: {
    isBool: function () {
      return {}.toString.call(this.modelValue) === '[object Boolean]'
    },
    isChecked: function () {
      if (this.isBool) {
        return this.modelValue
      } else {
        return this.modelValue.indexOf(this.arrValue) > -1
      }
    }
  }
}
</script>

<style>
.checkbox {
  border: 1px solid #9a9ca1;
  padding: 0 15px;
  user-select: none;
  box-sizing: border-box;
  height: 40px;
  display: flex;
  align-items: center;
}

.checkbox, .checkbox * {
  cursor: pointer;
}
.checkbox span {
  -webkit-appearance: none;
  display: inline-block;
  border: 1px solid #9a9ca1;
  height: 12px;
  width: 12px;
  margin-right: 8px;
  color: white;
  line-height: 12px
}

.checkbox span:focus {
  outline: none;
}

.checkbox span:hover {
  border: 1px solid #c80700;
}

.checkbox span.checked {
  border: 1px solid #c80700;
  background-color: #c80700;
}

.checkbox label {
  display: inline-block;
  color: inherit;
}

.active {
  border-color: #c80700;
  color: #c80700;
}
</style>