/* eslint-disable */
function j (f, h) {
  f[h >> 5] |= 128 << 24 - h % 32
  f[(h + 64 >> 9 << 4) + 15] = h
  for (var b = Array(80), a = 1732584193, c = -271733879, g = -1732584194, d = 271733878, k = -1009589776, l = 0; l < f.length; l += 16) {
    for (var o = a, p = c, q = g, r = d, s = k, e = 0; 80 > e; e++) {
      b[e] = 16 > e ? f[l + e] : (b[e - 3] ^ b[e - 8] ^ b[e - 14] ^ b[e - 16]) << 1 | (b[e - 3] ^ b[e - 8] ^ b[e - 14] ^ b[e - 16]) >>> 31
      var t = m(m(a << 5 | a >>> 27, 20 > e ? c & g | ~c & d : 40 > e ? c ^ g ^ d : 60 > e ? c & g | c & d | g & d : c ^ g ^ d), m(m(k, b[e]), 20 > e ? 1518500249 : 40 > e ? 1859775393 : 60 > e ? -1894007588 : -899497514)), k = d, d = g, g = c << 30 | c >>> 2, c = a, a = t
    }
    a = m(a, o)
    c = m(c, p)
    g = m(g, q)
    d = m(d, r)
    k = m(k, s)
  }
  return [a, c, g, d, k]
}
function m (f, h) {
  var b = (f & 65535) + (h & 65535)
  return (f >> 16) + (h >> 16) + (b >> 16) << 16 | b & 65535
}
function n (f) {
  for (var h = [], b = (1 << 8) - 1, a = 0; a < f.length * 8; a += 8)
    h[a >> 5] |= (f.charCodeAt(a / 8) & b) << 24 - a % 32
  return h
}
function hash (b, a) {
  var c = n(a)
  16 < c.length && (c = j(c, a.length * 8))
  for (var g = Array(16), a = Array(16), d = 0; 16 > d; d++)
    g[d] = c[d] ^ 909522486,
    a[d] = c[d] ^ 1549556828
  b = j(g.concat(n(b)), 512 + b.length * 8)
  b = j(a.concat(b), 672)
  c = ''
  for (a = 0; a < 4 * b.length; a += 3) {
    g = (b[a >> 2] >> 8 * (3 - a % 4) & 255) << 16 | (b[a + 1 >> 2] >> 8 * (3 - (a + 1) % 4) & 255) << 8 | b[a + 2 >> 2] >> 8 * (3 - (a + 2) % 4) & 255
    for (d = 0; 4 > d; d++)
      c = 8 * a + 6 * d > 32 * b.length ? c + '' : c + 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/'.charAt(g >> 6 * (3 - d) & 63)
  }
  return c.substr(0, 8)
}

export default hash