<template>
  <div>
    <h1>Noten</h1>
    <div>
      <input v-model="searchString" placeholder="Suche" />
    </div>
    <div v-if="!allScores.length">
      <div class="spinner">
        <i class="el-icon-loading"></i>
      </div>
    </div>
    <transition name="fadein">
      <div v-if="allScores.length">
        <ul class="folder">
          <li class="item" v-for="it in scores" v-bind:key="it.url">
            <div>
              <a :href="it.url">{{it.name}}</a> {{it.composer}}
            </div>
          </li>
        </ul>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapState } from 'pinia'
import { useUserStore } from '@/stores'
import api from '@/util/api'
import makeLink from '@/util/anchor'

export default {
  name: 'ScoresPage',
  data () {
    return {
      allScores: [],
      searchString: ''
    }
  },
  methods: {
    tokenize: function (title) {
      var reducer = function (acc, s) {
        s = s.replace(/[()_,:;!?.'"-]/g, '')
        s = s.toLowerCase()
        const diacritics = [
          {base: 'e', letters: /[éèëê]/},
          {base: 'a', letters: /[áàâå]/},
          {base: 'i', letters: /[íìïîī]/},
          {base: 'c', letters: /[çč]/},
          {base: 's', letters: /[sŝš]/},
          {base: 'z', letters: /[ž]/}
        ]
        diacritics.forEach(function (dia) {
          s = s.replace(dia.letters, dia.base)
        })
        acc.push(s)
        return acc
      }
      var tokens = title.split(' ').reduce(reducer, [])
      return tokens
    }
  },
  computed: {
    scores: function () {
      if (this.searchString === '') {
        return this.allScores
      }
      const sts = this.searchTokens
      return this.allScores.filter(score => {
        // all of the st must match any of the score's tokens
        return sts.reduce((accAll, st) => {
          const found = score.tokens.reduce((accAny, tok) => {
            return accAny || tok.startsWith(st)
          }, false)
          return accAll && found
        }, true)
      })
    },
    searchTokens: function () {
      return this.tokenize(this.searchString)
    },
    ...mapState(useUserStore, [
    'isLoggedIn'
  ])
  },
  created: function () {
    var that = this
    var re = /^(.*?)(?: \[(.*?)\])?$/
    api.get('/scores/all')
      .then(response => {
        that.allScores = response.data.map(it => {
          var matches = it.name.match(re)
          if (matches === null) {
            alert('Dateiname konnte nicht gematcht werden: ' + it.name)
          }

          var tokens = this.tokenize(matches[1])
          if (matches[2]) {
            tokens = tokens.concat(this.tokenize(matches[2]))
          }
          return {
            url: makeLink('/scores/get/' + it.key),
            name: it.name,
            tokens: tokens
          }
        })
      }).catch(alert)
  }
}
</script>

<style>
</style>