<template>
  <div>
    <a v-on:click="expanded = !expanded"><span class="fold">{{fold}}</span><slot name="title"></slot></a>
    <div class="inner" v-if="expanded">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FoldingToggleComponent',
  data () {
    return {
      expanded: false
    }
  },
  methods: {
  },
  computed: {
    fold: function () {
      return this.expanded ? '–' : '+'
    }
  }
}
</script>

<style>
.fold {
  width: 1em;
  padding-right: 0.5em;
  display: inline-block;
  text-align: right;
}

.inner {
  margin-left: 1.5em;
}
</style>