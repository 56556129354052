<template>
  <div>
    <div v-if="!error">Login-Token wird verarbeitet ...</div>
    <div v-if="error">{{ error }}</div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'pinia'
import { useUserStore, useMembersStore } from '@/stores'
import api from '@/util/api.js'

export default {
  name: 'LoginTokenPage',
  data: function () {
    return {
      error: null,
    }
  },
  created: function() {
    api.post('/loginWithToken', '"' + this.$route.params.token + '"')
      .then(response => {
        localStorage.setItem('id_token', response.data)
        this.syncAuthentication()
        this.syncMembers()
        this.$router.push('/')
      }).catch(() => {
        this.error = "Fehler: Login nicht erfolgreich"
      })
  },
  methods: {
    ...mapActions(useUserStore, ['syncAuthentication']),
    ...mapActions(useMembersStore, ['syncMembers'])
  },
  computed: {
    ...mapState(useUserStore, [
      'userId'
    ])
  }
}
</script>
