<template>
  <li>
    <div class="subfolder"
      @click="toggle">
      {{ name }}
      [<span class="constant-width">{{ open ? '-' : '+' }}</span>]
    </div>
    <transition name="fadein">
      <ul class="folder" v-show="open && (subfolders.length || files.length)">
        <FolderElem
          v-for="sub in subfolders"
          class="item"
          :key="sub.key"
          :s3key="sub.key"
          :name="sub.name">
        </FolderElem>
        <li class="item item-link" v-for="file in files" :key="file.url">
          <div v-if="file.name.endsWith('.mp3')">
            <a :href="file.url">{{file.name}}</a>

            <audio controls="" preload="none" 
            :src="file.url"/>
          </div>
          <a v-if="!file.name.endsWith('.mp3')" :href="file.url">
            <!-- https://stackoverflow.com/questions/980855/inputting-a-default-image-in-case-the-src-attribute-of-an-html-img-is-not-vali -->
            <object :data="file.thumbnailurl" type="image/jpg">
              <div>
                {{file.name}}
              </div>
            </object>
          </a>
        </li>
      </ul>
    </transition>
  </li>
</template>

<script>
import api from '@/util/api'

export default {
  name: 'FolderElem',
  props: {
    name: String,
    s3key: String
  },
  data: function () {
    return {
      open: false,
      subfolders: [],
      files: []
    }
  },
  methods: {
    toggle: function () {
      this.open = !this.open
      var that = this
      if (this.open && !this.subfolders.length && !this.files.length) {
        api.post('/media/contents', '"' + this.s3key + '"')
          .then(response => {
            that.subfolders = response.data[0]
            that.files = response.data[1]
          }).catch(alert)
      }
    }
  }
}
</script>

<style>
/* An item is always a li */
/* A *FolderElem* item contains either a subfolder div or an ul with items if expanded*/

.item-link {
  width: 100%;
}

.item-link a div {
  padding-left: 0.5em;
  padding-right: 0.5em;
}

.item-link a div:hover {
  background-color: rgba(200,7,0,1);
  color: white;
}

.subfolder:hover {
  background-color: rgba(200,7,0,0.1);
  color: inherit;
}

.item a {
    display: block; /* for mp3 titles to show above the audio element */
    color: inherit;
}

.item > div {
  cursor: pointer;
  padding-left: 0.5em;
}

</style>