<template>
  <div>
    <h1>Medien</h1>
    <div v-if="isLoggedIn">
      <img class="fullwidth" src="../assets/banner_medien.jpg" alt="Traumwelten-Konzert" />
      <div v-if="!Object.keys(items).length">
        <div class="spinner">
          <i class="el-icon-loading"></i>
        </div>
      </div>
      <transition name="fadein">
        <div v-if="Object.keys(items).length">
          <div v-for="(category, name) in items" :key="category">
            <h2>{{name}}</h2>
            <ul class="folder">
              <item class="item" v-for="sub in category" :key="sub.key" :s3key="sub.key" :name="sub.name"></item>
            </ul>
          </div>
          <a href="https://www.youtube.com/watch?v=9O6nhmCqKxc">Chorfilm 2016</a>
          <a href="https://www.youtube.com/watch?v=2gUw8r8tvTg">Trailer Traumwelten</a>
          <p>
            Die Links sind nur jeweils eine Stunde gültig.
          </p>
        </div>
      </transition>
    </div>
    <div v-else>
      <p>Eine Kostprobe aus unserem Filmmusik-Programm (Winter 2023):</p>
      <video class="fullwidth" controls>
        <source src="https://chantiervocal-public.s3.amazonaws.com/AusschnittFilmmusikkonzert.mp4" type="video/mp4" />
        <source src="https://chantiervocal-public.s3.amazonaws.com/AusschnittFilmmusikkonzert.webm" type="video/webm" />
      </video>
    </div>
  </div>
</template>

<script>
import { mapState } from 'pinia'
import { useUserStore } from '@/stores'
import api from '@/util/api'
import FolderElem from '@/components/FolderElem'

export default {
  name: 'MediaPage',
  components: {
    'item': FolderElem
  },
  data () {
    return {
      'items': {}
    }
  },
  methods: {
  },
  created: function () {
    var that = this
    if (this.isLoggedIn) {
      api.get('/media/index')
        .then(response => {
          that.items = response.data
        }).catch(alert)
    }
  },
  computed: mapState(useUserStore, [
    'isLoggedIn'
  ])
}
</script>

<style>
.fullwidth {
  width: 100%;
}

.spinner {
  margin: 0 auto;
  padding: 5em 0;
  text-align: center;
  font-size: 150%;
}

ul.folder {
  padding: 0;
  margin-left: 1em;
  border-left: 1px #c80700 solid;
}

li.item {
  list-style-type: none;
  padding-left: 0.5em;
  line-height: 2em;
}
</style>