<template>
  <div>
    <div>
      <label>Name</label>
      <span>{{member.name}}&nbsp;</span>
    </div>
    <div>
      <label>Email</label>
      <span>{{member.email}}&nbsp;</span>
    </div>
    <div>
      <label>Tel</label>
      <span>{{member.tel}}&nbsp;</span>
    </div>
    <div>
      <label>Stimme</label>
      <span>{{member.section}}&nbsp;</span>
    </div>
    <div>
      <label>Geburtstag (yyyy-mm-dd)</label>
      <span>{{member.birthday}}&nbsp;</span>
    </div>
    <div>
      <label>Adresse</label>
      <span>{{member.address}}&nbsp;</span>
    </div>
    <div>
      <label>Was ich sonst noch sagen wollte</label>
      <span>{{member.extraInfo}}&nbsp;</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ChoirMemberDisplay',
  props: ['member'],
  data () {
    return {
    }
  },
  methods: {
  }
}
</script>

<style>
.inner div {
  margin: 0.5em 0;
}
</style>
